<template>
    <div class="main">
        <div v-if="datalist.length">
            <van-button round type="info" icon="plus" color="linear-gradient(to right, #ff6034, #ee0a24)"
                @click="Comment">发表评论</van-button>
            <div class="comment-item" v-for="(item, index) in datalist" :key="index">
                <div class="comment-item-header-right">
                    <div class="comment-item-header-right-username">{{ item.username }}</div>
                    <div class="comment-item-header-right-time">{{ item.createTime }}</div>
                    <div class="comment-item-header-right-content">{{ item.content }}</div>
                    <div class="controls">
                        <div class="comment-item-header-right-like">
                            <van-icon name="like-o" class="like-button" @click="likeComment(index, item)" />
                            <span class="like-count">{{ item.like || 0 }}</span>
                        </div>
                        <div class="right">
                            <div class="comment-item-header-right-like" @click="deleteComment(index, item)"
                                v-if="item.isSelf">
                                <van-icon name="delete-o" class="like-button" />
                                <span class="like-count">删除</span>
                            </div>
                            <div class="comment-item-header-right-like" @click="Comment(item)">
                                <van-icon name="comment-circle-o" class="like-button" />
                                <span class="like-count">回复</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!datalist.length">
            <van-empty description="还没有过评论">
                <van-button round type="primary" class="bottom-button" @click="Comment">去评论</van-button>
            </van-empty>
        </div>
        <van-dialog v-model="show" show-cancel-button @confirm="handleComfirm">
            <van-field v-model="content" rows="4" autosize label="" type="textarea" maxlength="300"
                placeholder="请输入评论内容" show-word-limit />
        </van-dialog>
    </div>
</template>

<script>
import { Toast, Dialog} from 'vant';
export default {
    name: 'comment',
    props: {
        ctxid: String
    },
    data() {
        return {
            user: null,
            show: false,
            content: '',
            currentComment: {},
            datalist: [

            ]
        }
    },
    mounted() {
        console.log('comment', this.ctxid);
        this.$bus.$on('tabChange', (ctxid)=>{
            this.getComment()
        })
        this.getComment()
    },
    methods: {
        getComment(){
            fetch('comment/open/basic_comment/info/page', {
            body: JSON.stringify({
                "page": 1,
                "size": 20,
                "ctxid": this.ctxid + '',
                'user': this.$store.state.userinfo && this.$store.state.userinfo.email
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json()).then(res => {
            console.log('res==>>>', res);
            this.datalist = res.data.list;

        })
        },
        deleteComment(index, item) {
            fetch(`comment/open/basic_comment/info/delete`, {
                body: JSON.stringify({
                    "ids": item.id
                }),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(res => {
                console.log('res==>>>', res);
                if (res.code === 1000) {
                    Toast.success('删除成功');
                    this.datalist.splice(index, 1);
                } else {
                    Toast.fail('服务错误，请联系客服');
                }
            })
        },
        likeComment(index, item) {
            if (item.isLike) {
                Toast.fail('已经点过赞了')
                return
            }
            fetch(`comment/open/basic_comment/info/like?id=${item.id}`).then(res => res.json()).then(res => {
                console.log('res==>>>', res);
                if (res.code === 1000) {
                    Toast.success('点赞成功');
                    item.isLike = true
                    this.datalist[index].like = (this.datalist[index].like || 0) + 1;
                } else {
                    Toast.fail('服务错误，请联系客服');
                }
            })
        },
        Comment(item) {
            let tooltoken = localStorage.getItem('tooltoken');
            let _user = localStorage.getItem('userinfo');
            if (!tooltoken || !_user || (_user == 'undefined')) {
                Dialog({ message: '请先登录' });
                return;
            }
            this.currentComment = { ...item };
            this.show = true;
        },
        handleComfirm() {
            console.log(this.currentComment);
            let tooltoken = localStorage.getItem('tooltoken');
            let _user = localStorage.getItem('userinfo');
            if (!tooltoken || !_user || (_user == 'undefined')) {
                Dialog({ message: '请先登录' });
                return;
            }
            _user = JSON.parse(_user);
            fetch('comment/open/basic_comment/info/add', {
                body: JSON.stringify({
                    content: this.content,
                    username: _user.email,
                    ctxid: this.ctxid,
                    ...(this.currentComment.id && { parentid: this.currentComment.id })
                }),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(res => {
                console.log('res==>>>', res);
                if (res.code === 1000) {
                    Toast.success('评论成功');

                    this.datalist.unshift({
                        ...res.data,
                        isSelf: true
                    })
                } else {
                    Toast.fail('服务错误，请联系客服');
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.main {
    padding: 16px;
    background-color: #f9f9f9;
}

.comment-item {
    margin-top: 20px;
    margin-bottom: 16px;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comment-item-header-right {
    display: flex;
    flex-direction: column;
}

.comment-item-header-right-username {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.comment-item-header-right-time {
    font-size: 12px;
    color: #999;
    margin-top: 4px;
}

.comment-item-header-right-content {
    margin-top: 8px;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
}

.comment-item-header-right-like {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.like-button {
    font-size: 16px;
    color: #999;
    cursor: pointer;
    margin-right: 8px;
}

.like-count {
    font-size: 14px;
    color: #999;
}

.bottom-button {
    margin-top: 16px;
}

.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .like-button {
        margin-right: 4px;
    }

    .right {
        display: flex;

        div {
            margin-left: 20px;
        }
    }
}
</style>